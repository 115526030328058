// import logo from './logo.svg';
import LC from "./assets/LCCouleurs.png"
import Picto from './assets/PictoCouleurs.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <br/>
      <br/>
      <img src={LC} alt="FastWebApp" style={{width: "90%", margin: "auto"}}/>
      <header className="App-header">
        <p>Le site est actuellement en maintenance..</p>
        <p>Notre actualité se trouve ici <a className="Link-neo" href="https://neopresence.com">FastWebApp</a></p>
        <br/>
        <img src={Picto} className="App-logo" alt="logo" />
        <br/>
        <p>Si vous souhaitez nous contacter, vous pouvez nous appeler ou nous envoyer un mail.</p>
        <br/>
        <div style={{
          width: "100%",
          justifyContent: "center"
        }}>
          <a
            className="App-link"
            href="tel:+330615830730"
            target="_blank"
            rel="noopener noreferrer"
          >
            +33 6 15 83 07 30
          </a>
          <br/>
          <br/>
          <a
            className="App-link"
            href="mailto:hello@fastwebapp.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@fastwebapp.fr
          </a>
        </div>
        <br/>
        <p>Merci de votre comprehension</p>
      </header>
    </div>
  );
}

export default App;
